.form-themed table {
  color: var(--text-primary-color);
}
.form-themed table th,
.form-themed table td {
  font-size: 15px;
  font-weight: normal;
}
.form-themed table th {
  line-height: 60px;
}
.form-themed table td {
  line-height: 18px;
  padding: 0;
}
.form-themed .p-r-1 {
  padding-right: 1rem !important;
}
.form-themed label {
  margin-bottom: 5px;
}
.form-themed label.wrapperLabel {
  cursor: pointer;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) .wrapperText {
  display: block;
  order: -1;
}
.form-themed .btn[disabled],
.form-themed input[type=text][disabled],
.form-themed input[type=password][disabled],
.form-themed input[type=number][disabled],
.form-themed .btn.disabled,
.form-themed input[type=text].disabled,
.form-themed input[type=password].disabled,
.form-themed input[type=number].disabled,
.form-themed .btn[disabled]:hover,
.form-themed input[type=text][disabled]:hover,
.form-themed input[type=password][disabled]:hover,
.form-themed input[type=number][disabled]:hover,
.form-themed .btn.disabled:hover,
.form-themed input[type=text].disabled:hover,
.form-themed input[type=password].disabled:hover,
.form-themed input[type=number].disabled:hover,
.form-themed .btn[disabled]:active,
.form-themed input[type=text][disabled]:active,
.form-themed input[type=password][disabled]:active,
.form-themed input[type=number][disabled]:active,
.form-themed .btn.disabled:active,
.form-themed input[type=text].disabled:active,
.form-themed input[type=password].disabled:active,
.form-themed input[type=number].disabled:active {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-gray-light);
  color: var(--text-primary-color);
}
.form-themed .btn[disabled] + .wrapperText,
.form-themed input[type=text][disabled] + .wrapperText,
.form-themed input[type=password][disabled] + .wrapperText,
.form-themed input[type=number][disabled] + .wrapperText,
.form-themed .btn.disabled + .wrapperText,
.form-themed input[type=text].disabled + .wrapperText,
.form-themed input[type=password].disabled + .wrapperText,
.form-themed input[type=number].disabled + .wrapperText,
.form-themed .btn[disabled]:hover + .wrapperText,
.form-themed input[type=text][disabled]:hover + .wrapperText,
.form-themed input[type=password][disabled]:hover + .wrapperText,
.form-themed input[type=number][disabled]:hover + .wrapperText,
.form-themed .btn.disabled:hover + .wrapperText,
.form-themed input[type=text].disabled:hover + .wrapperText,
.form-themed input[type=password].disabled:hover + .wrapperText,
.form-themed input[type=number].disabled:hover + .wrapperText,
.form-themed .btn[disabled]:active + .wrapperText,
.form-themed input[type=text][disabled]:active + .wrapperText,
.form-themed input[type=password][disabled]:active + .wrapperText,
.form-themed input[type=number][disabled]:active + .wrapperText,
.form-themed .btn.disabled:active + .wrapperText,
.form-themed input[type=text].disabled:active + .wrapperText,
.form-themed input[type=password].disabled:active + .wrapperText,
.form-themed input[type=number].disabled:active + .wrapperText {
  color: var(--text-primary-color);
  cursor: auto;
}
.form-themed input[type=text],
.form-themed input[type=password],
.form-themed input[type=number] {
  background-color: var(--ui-gray);
  border-color: var(--ui-border-color);
  color: var(--text-primary-color);
  font-weight: normal;
}
.form-themed input[type=text]:active,
.form-themed input[type=password]:active,
.form-themed input[type=number]:active,
.form-themed input[type=text]:focus,
.form-themed input[type=password]:focus,
.form-themed input[type=number]:focus {
  background-color: var(--ui-gray-dark);
  border: 2px solid var(--active-color);
  box-shadow: none;
}
.form-themed input[type=text]:active + .wrapperText,
.form-themed input[type=password]:active + .wrapperText,
.form-themed input[type=number]:active + .wrapperText,
.form-themed input[type=text]:focus + .wrapperText,
.form-themed input[type=password]:focus + .wrapperText,
.form-themed input[type=number]:focus + .wrapperText {
  color: var(--active-color);
}
.form-themed .btn-primary {
  background-color: var(--active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .btn-primary:hover,
.form-themed .btn-primary:active,
.form-themed .btn-primary:focus,
.form-themed .btn-primary:focus:active {
  background-color: var(--ui-active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .form-control {
  background-color: #fff;
  border: 0;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  padding: 8px 9px 6px;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: ;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: ;
}
input[type=range] {
  display: block;
  width: 100%;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
  position: absolute;
  bottom: 121px;
  z-index: 3;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.study-list-header .addNewStudy {
  margin: 0 10px;
  color: var(--text-secondary-color);
}
.study-list-header .addNewStudy label {
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
.study-list-header .addNewStudy input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.study-list-header .addNewStudy:hover {
  color: var(--hover-color);
}
.study-list-header .addNewStudy:active {
  color: var(--active-color);
}
.study-list-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--ui-gray-darker);
  height: 75px;
  margin-bottom: 2px;
  padding: 0 8%;
  line-height: 75px;
}
.study-list-header .header {
  font-size: 22px;
  font-weight: 300;
  color: var(--table-text-secondary-color);
  line-height: 75px;
}
.study-list-header .actions {
  display: flex;
}
.study-list-header .study-count {
  color: var(--large-numbers-color);
  font-size: 40px;
  font-weight: 100;
  line-height: 75px;
}
/*
 * Dark gray background with blue border
 * Spans width of page to create a distinct area for table filters
 */
.table-head-background {
  height: 121px;
  position: absolute;
  width: 100%;
}
.table-head-background:before,
.table-head-background:after {
  content: '';
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}
.table-head-background:before {
  background-color: var(--ui-gray-darker);
  height: 100%;
  top: 0;
  z-index: 1;
}
.table-head-background:after {
  background-color: var(--ui-border-color-active);
  bottom: -1px;
  height: 1px;
  z-index: 2;
}
.study-list-container {
  width: 100%;
  padding: 0 8%;
  position: absolute;
  z-index: 2;
}
table.table {
  width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  color: var(--table-text-primary-color);
  font-weight: 300;
/* Striped Variant */
/* Hover Variant */
}
table.table > tbody tr {
  padding: 5px;
  background-color: #000;
}
table.table > tbody td {
  padding: 16px 8px;
  height: 40px;
  word-wrap: break-word;
}
table.table.table--striped > tbody tr:nth-child(even) {
  background-color: var(--ui-gray-darker);
}
table.table.table--hoverable > tbody tr:hover,
table.table.table--hoverable > tbody tr:active,
table.table.table--hoverable > tbody tr.active {
  background-color: var(--table-hover-color);
}
table.table.table--hoverable > tbody tr.no-hover:hover,
table.table.table--hoverable > tbody tr.no-hover:active,
table.table.table--hoverable > tbody tr.no-hover.active {
  background-color: var(--ui-gray-darker);
}
table.table .empty-value {
  color: var(--ui-gray-light);
}
.study-list-container > table.table > tr {
  height: 20px;
}
.study-list-container > table.table > thead ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
.study-list-container > table.table > thead ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
.study-list-container > table.table > thead ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.study-list-container > table.table > thead > tr > th {
  padding: 0;
  border-bottom: 1px solid var(--ui-border-color-active);
  width: 100%;
  text-align: left;
  border-top: 0;
}
.study-list-container > table.table > thead > tr > th.StudyDate {
  min-width: 230px;
}
.study-list-container > table.table .modalities {
  font-weight: 500;
  min-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.study-list-container .filters label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  min-width: 95px;
  margin: 0 auto;
  color: var(--table-text-primary-color);
  font-weight: 400;
  padding: 20px 8px;
  user-select: none;
  font-size: 15px;
}
.study-list-container .filters label:hover {
  color: var(--active-color);
}
.study-list-container .filters label.active,
.study-list-container .filters label:active {
  color: var(--active-color);
}
.study-list-container .filters i {
  margin: 0 5px;
}
.study-list-container input {
  height: 40px;
  margin: 0 5px 20px 5px;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  background-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  font-size: 10pt;
  font-weight: normal;
  border-radius: 4px;
  width: calc(100% - 10px); /* Just use padding? */
}
.study-list-container input::-webkit-input-placeholder {
  color: var(--input-placeholder-color);
}
.study-list-container input:-moz-placeholder {
  color: var(--input-placeholder-color);
}
.study-list-container input::-moz-placeholder {
  color: var(--input-placeholder-color);
}
.study-list-container input:-ms-input-placeholder {
  color: var(--input-placeholder-color);
}
.study-list-container input:active,
.study-list-container input:hover {
  background-color: var(--input-background-color);
}
.study-list-container .loading-text {
  color: var(--table-text-secondary-color);
  text-align: center;
  font-size: 30px;
}
.study-list-container .notFound {
  color: var(--table-text-secondary-color);
  font-size: 30px;
  font-weight: 200;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .study-list-header {
    padding: 0 16px;
  }
  .study-list-container {
    padding: 0;
  }
  .study-list-container > table.table > thead > tr > th {
    padding: 0 13px;
  }
  .study-list-container > table.table > tbody > tr > td {
    padding: 8px;
  }
  .study-list-container .filters label {
    padding: 8px;
  }
}
@media only screen and (max-width: 500px) {
  .hide-xs {
    display: none;
  }
}
