:root {
  --active-color: #20a5d6;
}
.separator {
  background: var(--active-color);
  height: 85%;
  left: 50%;
  margin: -1px;
  top: 0;
}
